import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../component/Layout'
import SEO from '../../component/SEO'
import ContentRender from '../../component/ContentRender'
import { NodePostFieldsFragment, PostTemplateQueryQuery } from '../../types/generated'
import { LanguageCode } from '../../types/LanguageCode'
import DetailPageHeader from '../../component/DetailPageHeader'
import { getSeoImage } from '../../utils'
// @ts-ignore
import { translate } from '../../translate'

interface Props {
  data: PostTemplateQueryQuery
}

export const query = graphql`
  query PostTemplateQuery($id: String!, $nid: Int!, $langcode: String!) {
    ...HeaderMenu
    ...FooterMenu
    ...SocialMenu

    nodePost(id: { eq: $id }) {
      title
      langcode
      field_date(formatString: "MM-DD-yyyy")
      bodySummary
      body {
        summary
        value
      }
      path {
        alias
      }
      relationships {
        field_post_image {
          thumbnail {
            alt
          }
          gatsbyImageData(width: 1200, aspectRatio: 2)
          relationships {
            field_media_image {
              uri {
                value
              }
            }
          }
        }
      }
    }

    englishPage: nodePost(drupal_internal__nid: { eq: $nid }, langcode: { eq: "en" }) {
      path {
        alias
      }
    }

    spanishPage: nodePost(drupal_internal__nid: { eq: $nid }, langcode: { eq: "es" }) {
      path {
        alias
      }
    }

    somaliPage: nodePost(drupal_internal__nid: { eq: $nid }, langcode: { eq: "so" }) {
      path {
        alias
      }
    }

    vietnamesePage: nodePost(drupal_internal__nid: { eq: $nid }, langcode: { eq: "vi" }) {
      path {
        alias
      }
    }

    filipinoPage: nodePost(drupal_internal__nid: { eq: $nid }, langcode: { eq: "fil" }) {
      path {
        alias
      }
    }
  }
`

const Post = ({ data }: Props) => {
  const post = data.nodePost

  const {
    footerMenu,
    headerMenu,
    socialMenu,
    englishPage,
    spanishPage,
    somaliPage,
    vietnamesePage,
    filipinoPage,
  } = data

  const translationPaths = {
    en: englishPage?.path?.alias,
    es: spanishPage?.path?.alias,
    so: somaliPage?.path?.alias,
    vi: vietnamesePage?.path?.alias,
    fil: filipinoPage?.path?.alias,
  }

  const menu = {
    header: headerMenu.nodes,
    footer: footerMenu.nodes,
    social: socialMenu.nodes,
  }

  const t = (text: string) => translate(post?.langcode, text)

  return (
    <Layout
      menu={menu}
      translationPaths={translationPaths}
      langcode={post?.langcode as LanguageCode}
    >
      <SEO
        title={post?.title}
        description={post?.bodySummary}
        image={getSeoImage(post?.relationships?.field_post_image)}
        url={post?.path?.alias}
      />
      <section>
        <div className="mb-4">
          <DetailPageHeader header={post as NodePostFieldsFragment} />
        </div>
        <ContentRender>
          <div className="text-gray-500">
            {t('Published on:')} {post?.field_date}
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: post?.body?.value ?? '' }} />
        </ContentRender>
      </section>
    </Layout>
  )
}

export default Post
